const typography = {
  fontFamily: "'DM Sans', sans-serif",
  body1: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400, // or 'bold'
  },
  body1medium: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500, // or 'bold'
  },
  body2: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400, // or 'bold'
  },
  body2medium: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500, // or 'bold'
  },
  body2bold: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700, // or 'bold'
  },
  caption12: {
    fontSize: '12px',
    lineHeight: '19px',
    fontWeight: 400, // or 'bold'
  },
  caption10: {
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 400, // or 'bold'
  },
  h1: {
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: '45px',
  },
  h2: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '36px',
  },
  h3: {
    fontWeight: 500,
    fontSize: '21px',
    lineHeight: '31.5px',
  },
  h4: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '27px',
  },
  h5: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  h6: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
  },
  button: {
    textTransform: 'none',
    fontWeight: '400',
  },
  subtitle1: {
    fontSize: '16px',
    fontWeight: '400',
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: '400',
  },
  subtitle3: {
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '2px',
  },
  header1: {
    color: '#8F90A6',
    fontWeight: '500',
    fontSize: '16px'
  },
  row500: {
    color: '#555770',
    fontWeight: '500',
    fontSize: '14px'
  },
  row400: {
    color: '#555770',
    fontWeight: '400',
    fontSize: '14px'
  }
};

export default typography;
